class subtitles {
    //物件建立時產生
    constructor(){
        // this.navData = {
        //     sub_tile:'儀表板',
        //     isBack:false,
        //     acvtion:[]
        // }
    }

    setSubTitleObj(data,ary){
        ary.acvtion = []
        ary.isBack = false
        ary.isLink = null
        switch (data.name) {
            case 'dashboard':
                ary.sub_tile = '儀表板'
                break;
            case 'account-list':
                ary.sub_tile = '帳號管理'
                ary.acvtion = [
                    {
                      name:'建立',
                      icon:'fa-plus-circle',
                      to:'/account/add'
                    }
                  ]
                 break;
            case 'account-info':
                ary.sub_tile = '帳號資訊'
                ary.isBack = true
                break;
            case 'account-add':
                ary.sub_tile = '建立帳號'
                ary.isBack = true
                break;
            case 'account-edit':
                ary.sub_tile = '編輯帳號'
                ary.isBack = true
                break;
            case 'system-list':
                ary.sub_tile = '掛號系統列表'
                ary.acvtion = [
                    {
                      name:'建立',
                      icon:'fa-plus-circle',
                      to:'/system/add'
                    }
                ]
                break;
            case 'system-info':
                ary.sub_tile = '掛號系統詳細資訊'
                ary.isBack = true
                break;
            case 'system-callback':
                ary.sub_tile = 'callback設定'
                ary.isBack = true
                break;
            case 'system-add':
                ary.sub_tile = '建立掛號系統'
                ary.isBack = true
                break;
            case 'system-edit':
                ary.sub_tile = '編輯掛號系統'
                ary.isBack = true
                break;
            default:
              ary.sub_tile = 'subtitles.js 未設定'
              break;
        }
        return ary
    }
}
export default subtitles
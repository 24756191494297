<template>
    <div class="side-menu">
        <ul class="main-menu">
            <li v-for="(mainItem,i)  in mainMenu" :key="i">
                <h4 class="mb-0">{{mainItem.name}}</h4>
                <ul class="sub-menu" v-if="mainItem.childMenu">
                    <li v-for="(childItem,n)  in mainItem.childMenu" :key="n">
                        <router-link class="router-link" :to="childItem.to">
                            <i class="align-middle fas fa-fw fs-19" v-bind:class="[childItem.icon]"></i> <span class="align-middle">{{childItem.name}}</span>
                        </router-link>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</template>
<script>
    export default {
        name: 'sideMenu',
        data() {
            return {
                mainMenu:[
                    {
                        name:'總覽',
                        childMenu:[
                            {
                                name:'儀表板',
                                to:'/',
                                icon:'fa-tachometer-alt'
                            }
                            
                        ]
                    },
                    {
                        name:'掛號系統管理',
                        childMenu:[
                            {
                                name:'掛號系統管理',
                                to:'/system/list',
                                icon:'fa-chalkboard'
                            }
                        ]
                    },
                    {
                        name:'系統管理',
                        childMenu:[
                            {
                                name:'帳號管理',
                                to:'/account/list',
                                icon:'fa-users'
                            },
                            // {
                            //     name:'公司資訊',
                            //     to:'/company/info',
                            //     icon:'fa-building'
                            // },
                            // {
                            //     name:'刪除公司',
                            //     to:'/company/del',
                            //     icon:'fa-trash-alt'
                            // }
                        ]
                    }

                ]
            } 
        },
        props:[
            
        ],
        methods:{
            
        }
        
    }
</script>
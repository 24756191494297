import accountRoutes from './accountRoutes'
import systemRoutes from './systemRoutes'
const routes = [
    {
        path:'/',
        name:'dashboard',
        component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/index.vue')
    },
    {
        path:'/system',
        name:'system',
        children:systemRoutes
    },
    {
        path:'/account',
        name:'account',
        children:accountRoutes
    },
]
export default routes
const systemRoutes = [
    {
        path:'list',
        name:'system-list',
        component: () => import(/* webpackChunkName: "about" */ '../views/system/list.vue')
    },
    {
        path:'info',
        name:'system-info',
        component: () => import(/* webpackChunkName: "about" */ '../views/system/info.vue')
    },
    {
        path:'add',
        name:'system-add',
        component: () => import(/* webpackChunkName: "about" */ '../views/system/add.vue')
    },
    {
        path:'edit',
        name:'system-edit',
        component: () => import(/* webpackChunkName: "about" */ '../views/system/edit.vue')
    },
    {
        path:'callback',
        name:'system-callback',
        component: () => import(/* webpackChunkName: "about" */ '../views/system/callback.vue')
    }
]
export default systemRoutes